import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuestState } from './QuestStateProvider';
import SpeechStage from './SpeechStage';
import RiddleStage from './RiddleStage';
import ImageStage from './ImageStage';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import api from '../api';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Progress } from './ui/progress';
import { Alert, AlertDescription } from './ui/alert';
import {
  Shield,
  Crown,
  Users,
  Award,
  Clock,
  AlertCircle,
  CheckCircle
} from 'lucide-react';
import {
  createWebSocket,
  closeWebSocket,
  sendMessage,
  addEventListener,
  removeEventListener,
} from '../socket';
import './QuestContent.css';

const QuestContent = ({ quest }) => {
  const navigate = useNavigate();
  const {
    currentStep,
    currentSubStep,
    points,
    role,
    isTeam,
    teamId,
    error: stateError,
    updateState,
    applyHint,
    addIncorrectAttempt,
    isSyncing,
    isLeader,
  } = useQuestState();

  const [inputText, setInputText] = useState('');
  const [image, setImage] = useState(null);
  const [resultMessage, setResultMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [memberStates, setMemberStates] = useState({});
  
  const progressionRef = useRef(false);
  const navigationLockRef = useRef(false);
  const stateUpdateLockRef = useRef(false);
  const lastUpdateRef = useRef(null);

  // Получение состава команды
  useEffect(() => {
    const fetchTeamData = async () => {
      if (!isTeam || !teamId) return;

      try {
        const response = await api.get(`/team/status/${teamId}`);
        setTeamMembers(response.data.members);
      } catch (error) {
        console.error('Error fetching team data:', error);
        setLocalError('Failed to load team data');
      }
    };

    fetchTeamData();
  }, [isTeam, teamId]);

  // WebSocket подключение
  useEffect(() => {
    const initializeWebSocket = async () => {
      try {
        if (isTeam && teamId) {
          await createWebSocket(teamId, true);
        } else {
          await createWebSocket(quest._id, false);
        }

        // Обработчики WebSocket событий
        const handleStateUpdate = (data) => {
          if (data.timestamp && (!lastUpdateRef.current || 
              new Date(data.timestamp) > new Date(lastUpdateRef.current))) {
            lastUpdateRef.current = data.timestamp;

            const newState = {
              current_step: data.current_step,
              current_substep: data.current_substep,
              points: data.points,
              timestamp: data.timestamp
            };

            updateState(newState);

            // Автоматический переход при обновлении состояния
            if (data.current_step !== currentStep || data.current_substep !== currentSubStep) {
              navigate(`/quest/${quest._id}/step/${data.current_step}/substep/${data.current_substep}`, 
                { replace: true });
            }
          }
        };

        addEventListener('state_update', handleStateUpdate);
        
        return () => {
          removeEventListener('state_update', handleStateUpdate);
          closeWebSocket();
        };
      } catch (error) {
        console.error('WebSocket initialization error:', error);
        setLocalError('Failed to establish real-time connection');
      }
    };

    initializeWebSocket();
  }, [quest._id, isTeam, teamId, currentStep, currentSubStep, updateState, navigate]);

  const safeNavigate = useCallback(async (path) => {
    if (navigationLockRef.current) return;
    navigationLockRef.current = true;

    try {
      await new Promise((resolve) => setTimeout(resolve, 100));
      navigate(path, { replace: true });
    } finally {
      setTimeout(() => {
        navigationLockRef.current = false;
      }, 500);
    }
  }, [navigate]);

  const handleStepProgression = async () => {
    if (progressionRef.current || isProcessing || isSyncing || navigationLockRef.current) {
      return;
    }

    try {
      progressionRef.current = true;
      const currentPoint = quest.points[currentStep - 1];
      if (!currentPoint) {
        throw new Error('Invalid current point');
      }

      let nextStep = currentStep;
      let nextSubStep = currentSubStep;

      if (currentSubStep + 1 >= currentPoint.steps.length) {
        if (currentStep >= quest.points.length) {
          const finalState = {
            is_completed: true,
            completed_at: new Date().toISOString(),
            points: points,
          };

          if (isTeam && teamId) {
            await api.post(`/team/${teamId}/complete`, finalState);
          } else {
            await updateState(finalState);
          }

          await safeNavigate(`/quest/${quest._id}/complete`);
          return;
        }
        nextStep = currentStep + 1;
        nextSubStep = 0;
      } else {
        nextSubStep = currentSubStep + 1;
      }

      const timestamp = new Date().toISOString();
      const newState = {
        current_step: nextStep,
        current_substep: nextSubStep,
        points: points,
        timestamp: timestamp
      };

      if (isTeam && teamId) {
        // Отправляем WebSocket сообщение с обновлением состояния
        await sendMessage({
          type: 'state_update',
          data: {
            ...newState,
            team_id: teamId
          }
        });
      }

      // Локальное обновление состояния и переход
      await updateState(newState);
      await safeNavigate(`/quest/${quest._id}/step/${nextStep}/substep/${nextSubStep}`);

    } catch (error) {
      console.error('Error in progression:', error);
      setLocalError(error.message || 'Failed to progress to next step');
    } finally {
      progressionRef.current = false;
    }
  };

  const handleTextSubmit = async (event) => {
    event.preventDefault();

    if (isProcessing || !inputText.trim() || isSyncing || navigationLockRef.current) {
      return;
    }

    const currentStage = getCurrentStage();
    if (!currentStage?.correctAnswer) return;

    setIsProcessing(true);
    try {
      const correctAnswers = currentStage.correctAnswer
        .split('|')
        .map((answer) => answer.trim().toLowerCase());
      const userAnswer = inputText.toLowerCase().trim();

      const isCorrect = correctAnswers.some((answer) => answer === userAnswer);

      if (isCorrect) {
        setResultMessage('Correct!');
        setInputText('');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await addIncorrectAttempt();
        setResultMessage(
          `Incorrect answer. Please try again! Points remaining: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error processing answer:', error);
      setLocalError('Error checking answer');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleImageSubmit = async (event) => {
    event.preventDefault();

    if (isProcessing || !image || isSyncing || navigationLockRef.current) return;

    setIsProcessing(true);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('quest_id', quest._id);
    formData.append('point_index', currentStep - 1);
    formData.append('step_index', currentSubStep);

    try {
      const response = await api.uploadImage(formData);
      const similarity = response.data.similarity || 0;
      const SIMILARITY_THRESHOLD = 25.0;

      if (similarity >= SIMILARITY_THRESHOLD) {
        setImage(null);
        setResultMessage('Image matches the template!');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await handleStepProgression();
      } else {
        const result = await addIncorrectAttempt();
        setResultMessage(
          `Image doesn't match. Required similarity: ${SIMILARITY_THRESHOLD}%, got: ${similarity.toFixed(2)}%. Points: ${result?.points || points}`
        );
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setLocalError(error.response?.data?.message || 'Error uploading image');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleHint = async (hintId) => {
    if (!hintId || isSyncing) return;

    try {
      const result = await applyHint(hintId);
      if (result) {
        setResultMessage(`Hint applied. Points remaining: ${result.points}`);
      }
    } catch (error) {
      console.error('Error applying hint:', error);
      setLocalError('Error showing hint');
    }
  };

  const getCurrentStage = useCallback(() => {
    if (!quest?.points?.[currentStep - 1]?.steps?.[currentSubStep]) {
      return null;
    }
    return quest.points[currentStep - 1].steps[currentSubStep];
  }, [quest, currentStep, currentSubStep]);

  const renderTeamInfo = () => {
    if (!isTeam || !teamMembers.length) return null;

    return (
      <div className="mb-4 p-4 bg-background/50 rounded-lg">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <Users className="h-5 w-5 text-primary" />
            <h3 className="font-medium">Team Members</h3>
          </div>
          <Award className="h-5 w-5 text-yellow-500" />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {teamMembers.map((member) => (
            <div key={member.userId} 
                className="flex items-center justify-between p-2 bg-background/30 rounded">
              <div className="flex items-center gap-2">
                {member.isLeader && <Crown className="h-4 w-4 text-yellow-500" />}
                <span>{member.username}</span>
                <Shield className="h-4 w-4 text-muted-foreground" />
                <span className="text-sm text-muted-foreground">{member.role}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderStageContent = () => {
    const currentStage = getCurrentStage();
    if (!currentStage) return null;

    const commonProps = {
      character: quest.points[currentStep - 1].character,
      text: currentStage.text,
      audioFile: currentStage.audioFile,
      questId: quest._id,
      isTeam,
      teamId,
      role
    };

    switch (currentStage.type) {
      case 'speech':
        return (
          <SpeechStage
            {...commonProps}
            onNext={handleStepProgression}
          />
        );

      case 'riddle':
        return (
          <RiddleStage
            {...commonProps}
            inputText={inputText}
            onInputChange={(e) => setInputText(e.target.value)}
            onSubmit={handleTextSubmit}
            showHint={!!currentStage.hint && points > 10}
            onHint={() => handleHint(currentStage.id)}
            resultMessage={resultMessage}
            isProcessing={isProcessing}
            hint={currentStage.hint}
          />
        );

      case 'image':
        return (
          <ImageStage
            {...commonProps}
            onFileChange={(e) => setImage(e.target.files[0])}
            onSubmit={handleImageSubmit}
            showHint={!!currentStage.hint && points > 10}
            onHint={() => handleHint(currentStage.id)}
            resultMessage={resultMessage}
            isProcessing={isProcessing}
            hint={currentStage.hint}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    setResultMessage('');
  }, [currentStep, currentSubStep]);

  if (stateError || localError) {
    return (
      <ErrorMessage
        message={stateError || localError}
        retryAction={() => {
          setLocalError(null);
          window.location.reload();
        }}
      />
    );
  }

  if (!quest) return null;

  const currentPoint = quest.points[currentStep - 1];
  const totalSteps = quest.points.reduce((total, point) => total + point.steps.length, 0);
  const currentTotalStep = quest.points
    .slice(0, currentStep - 1)
    .reduce((total, point) => total + point.steps.length, 0) + currentSubStep + 1;
  const progress = (currentTotalStep / totalSteps) * 100;

  return (
    <div className="quest-content">
      <Card className="mb-6">
        <CardHeader>
          <CardTitle className="flex items-center justify-between">
            <h1>{quest.name}</h1>
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              {isTeam ? (
                <>
                  <Users className="h-4 w-4" />
                  <span>Team Quest</span>
                  {isLeader && (
                    <>
                      <Crown className="h-4 w-4 text-yellow-500" />
                      <span>Leader</span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Shield className="h-4 w-4" />
                  <span>Solo Quest</span>
                </>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        
        <CardContent>
          {renderTeamInfo()}
          <div className="space-y-2">
            <div className="flex justify-between text-sm">
              <span>Step {currentStep} of {quest.points.length}</span>
              <span>Points: {points}</span>
            </div>
            <Progress value={progress} />
          </div>
        </CardContent>
      </Card>

      {renderStageContent()}
    </div>
  );
};

QuestContent.propTypes = {
  quest: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        character: PropTypes.string.isRequired,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.oneOf(['speech', 'riddle', 'image']).isRequired,
            text: PropTypes.string.isRequired,
            correctAnswer: PropTypes.string,
            hint: PropTypes.string,
            audioFile: PropTypes.string,
            id: PropTypes.string,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default QuestContent;
