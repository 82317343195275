import log from 'loglevel';

log.setLevel('DEBUG');

let socket = null;
let listeners = new Map();
let reconnectAttempts = 0;
let messageQueue = [];
let lastProcessedState = null;

const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_DELAY = 1000;
const STATE_UPDATE_TIMEOUT = 5000;

const getWebSocketUrl = (id, isTeam = false) => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  const baseUrl = `${protocol}//${window.location.host}/ws`;
  return isTeam ? `${baseUrl}/team/${id}` : `${baseUrl}/${id}`;
};

const processMessageQueue = async () => {
  if (!socket || socket.readyState !== WebSocket.OPEN || !messageQueue.length) {
    return;
  }

  while (messageQueue.length > 0) {
    const message = messageQueue[0];
    try {
      await socket.send(JSON.stringify(message));
      log.debug('Message sent from queue:', message);
      messageQueue.shift();
    } catch (error) {
      log.error('Error sending message from queue:', error);
      break;
    }
  }
};

export const createWebSocket = (id, isTeam = false) => {
  return new Promise((resolve, reject) => {
    try {
      if (socket) {
        socket.close();
      }

      log.debug(`Connecting to WebSocket: ${getWebSocketUrl(id, isTeam)}`);
      socket = new WebSocket(getWebSocketUrl(id, isTeam));

      socket.onopen = () => {
        log.info(`WebSocket connected for ${isTeam ? 'team' : 'quest'} ${id}`);
        reconnectAttempts = 0;
        
        // Send initial connection message
        sendMessage({
          type: 'connect',
          data: {
            id,
            isTeam,
            timestamp: new Date().toISOString()
          }
        });

        // Process queued messages
        processMessageQueue();
        resolve(socket);
      };

      socket.onclose = async (event) => {
        log.info('WebSocket connection closed', event);
        
        if (!event.wasClean && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts++;
          const delay = Math.min(RECONNECT_DELAY * Math.pow(2, reconnectAttempts), 30000);
          log.info(`Attempting to reconnect in ${delay}ms (${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS})`);
          
          if (!isTeam) {
            try {
              await new Promise(resolve => setTimeout(resolve, delay));
              await createWebSocket(id, false);
            } catch (error) {
              log.error('Reconnection failed:', error);
            }
          }
        }
      };

      socket.onerror = (error) => {
        log.error('WebSocket error:', error);
        reject(error);
      };

      socket.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
          log.debug('Received message:', message);

          if (message.type === 'state_update') {
            const newState = message.data;
            
            // Check if this is a newer state
            if (!lastProcessedState || 
                new Date(newState.timestamp) > new Date(lastProcessedState.timestamp)) {
              lastProcessedState = newState;
              
              // Send confirmation
              await sendMessage({
                type: 'state_update_confirmed',
                data: {
                  timestamp: newState.timestamp
                }
              });

              // Notify listeners
              const stateListeners = listeners.get('state_update');
              if (stateListeners) {
                stateListeners.forEach(callback => {
                  try {
                    callback(newState);
                  } catch (error) {
                    log.error('Error in state update handler:', error);
                  }
                });
              }
            }
          } else {
            // Handle other message types
            const messageListeners = listeners.get(message.type);
            if (messageListeners) {
              messageListeners.forEach(callback => {
                try {
                  callback(message.data);
                } catch (error) {
                  log.error(`Error in message handler for ${message.type}:`, error);
                }
              });
            }
          }
        } catch (error) {
          log.error('Error processing message:', error);
        }
      };

    } catch (error) {
      log.error('Error creating WebSocket:', error);
      reject(error);
    }
  });
};

export const sendMessage = async (message) => {
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    log.warn('WebSocket is not connected. Queueing message:', message);
    messageQueue.push(message);
    return;
  }

  try {
    const messageWithTimestamp = {
      ...message,
      timestamp: message.timestamp || new Date().toISOString()
    };

    if (message.type === 'state_update') {
      // For state updates, wait for confirmation
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('State update timeout'));
        }, STATE_UPDATE_TIMEOUT);

        const handleConfirmation = (data) => {
          if (data.timestamp === messageWithTimestamp.timestamp) {
            clearTimeout(timeout);
            removeEventListener('state_update_confirmed', handleConfirmation);
            resolve(data);
          }
        };

        addEventListener('state_update_confirmed', handleConfirmation);
        socket.send(JSON.stringify(messageWithTimestamp));
      });
    } else {
      // For other messages, just send
      await socket.send(JSON.stringify(messageWithTimestamp));
    }
    
    log.debug('Message sent:', messageWithTimestamp);
  } catch (error) {
    log.error('Error sending message:', error);
    messageQueue.push(message);
    throw error;
  }
};

export const addEventListener = (event, callback) => {
  if (!listeners.has(event)) {
    listeners.set(event, new Set());
  }
  listeners.get(event).add(callback);
  log.debug(`Added listener for event: ${event}`);
};

export const removeEventListener = (event, callback) => {
  if (listeners.has(event)) {
    listeners.get(event).delete(callback);
    if (listeners.get(event).size === 0) {
      listeners.delete(event);
    }
    log.debug(`Removed listener for event: ${event}`);
  }
};

export const closeWebSocket = () => {
  if (socket) {
    if (socket.readyState === WebSocket.OPEN) {
      sendMessage({
        type: 'disconnect',
        timestamp: new Date().toISOString()
      });
    }
    socket.close();
    socket = null;
    listeners.clear();
    reconnectAttempts = 0;
    messageQueue = [];
    lastProcessedState = null;
    log.info('WebSocket connection closed and cleaned up');
  }
};

export const isConnected = () => {
  return socket && socket.readyState === WebSocket.OPEN;
};

export const getQueueLength = () => {
  return messageQueue.length;
};
