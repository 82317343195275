import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api, { logout } from '../api';
import { 
  User, 
  History, 
  CreditCard, 
  LogOut, 
  ChevronRight,
  Clock,
  Award,
  ScrollText
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertDescription } from './ui/alert';

const Account = () => {
  const [userData, setUserData] = useState({});
  const [questHistory, setQuestHistory] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [purchasedQuests, setPurchasedQuests] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch user profile data
        const [
          profileResponse,
          questHistoryResponse,
          transactionHistoryResponse,
          purchasedQuestsResponse
        ] = await Promise.all([
          api.get('/account/profile'),
          api.get('/account/quest-history'),
          api.get('/account/transaction-history'),
          api.get('/account/purchased-quests')
        ]);

        setUserData(profileResponse.data);
        setQuestHistory(questHistoryResponse.data);
        setTransactionHistory(transactionHistoryResponse.data);
        setPurchasedQuests(purchasedQuestsResponse.data);

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          logout();
          navigate('/login');
        } else {
          setError('Failed to load account data');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-4 md:p-8">
      <div className="max-w-6xl mx-auto space-y-6">
        {/* Header */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="space-y-1">
              <CardTitle className="text-2xl font-bold flex items-center">
                <User className="mr-2 h-6 w-6" />
                {userData.username}
              </CardTitle>
              <CardDescription>{userData.email}</CardDescription>
            </div>
            <Button variant="outline" onClick={handleLogout}>
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button>
          </CardHeader>
        </Card>

        {/* Statistics Grid */}
        <div className="grid gap-4 md:grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Active Quests
              </CardTitle>
              <ScrollText className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{purchasedQuests.length}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Points
              </CardTitle>
              <Award className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {questHistory.reduce((total, quest) => total + quest.points, 0)}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Quests Completed
              </CardTitle>
              <History className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{questHistory.length}</div>
            </CardContent>
          </Card>
        </div>

        {/* Quest History */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <History className="mr-2 h-5 w-5" />
              Quest History
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {questHistory.length === 0 ? (
                <p className="text-sm text-muted-foreground">No quest history available.</p>
              ) : (
                questHistory.map((quest, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-border pb-4 last:border-0 last:pb-0"
                  >
                    <div className="flex items-center space-x-4">
                      <div className="space-y-1">
                        <p className="text-sm font-medium leading-none">{quest.quest_name}</p>
                        <p className="text-sm text-muted-foreground">
                          {new Date(quest.date).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="flex items-center">
                        <Award className="mr-1 h-4 w-4 text-yellow-500" />
                        <span>{quest.points} points</span>
                      </div>
                      <ChevronRight className="h-4 w-4 text-muted-foreground" />
                    </div>
                  </div>
                ))
              )}
            </div>
          </CardContent>
        </Card>

        {/* Transactions */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <CreditCard className="mr-2 h-5 w-5" />
              Transaction History
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {transactionHistory.length === 0 ? (
                <p className="text-sm text-muted-foreground">No transaction history available.</p>
              ) : (
                transactionHistory.map((transaction, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-border pb-4 last:border-0 last:pb-0"
                  >
                    <div className="space-y-1">
                      <p className="text-sm font-medium leading-none">
                        {transaction.transaction_id}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {new Date(transaction.date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className={`px-2 py-1 rounded text-sm ${
                        transaction.status === 'completed' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {transaction.status}
                      </span>
                      <span className="font-medium">${transaction.amount}</span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </CardContent>
        </Card>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <Button variant="outline" asChild className="w-full">
          <Link to="/">
            Back to Home
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default Account;
