import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

export const useQuest = (questId) => {
    const [quest, setQuest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuest = async () => {
            try {
                setLoading(true);
                setError(null);

                // Проверяем режим квеста
                const questMode = localStorage.getItem(`questMode_${questId}`);
                const questModeData = questMode ? JSON.parse(questMode) : null;
                const isTeamMode = questModeData?.isTeam;
                const teamId = questModeData?.teamId;

                // Проверяем сохраненный квест
                const savedQuest = localStorage.getItem('currentQuest');
                const savedQuestData = savedQuest ? JSON.parse(savedQuest) : null;

                // Загружаем квест
                let questData;
                if (savedQuestData && savedQuestData._id === questId) {
                    questData = savedQuestData;
                } else {
                    const response = await api.get(`/quests/${questId}`);
                    questData = response.data;
                    localStorage.setItem('currentQuest', JSON.stringify(questData));
                }
                setQuest(questData);

                // Загружаем состояние
                if (isTeamMode && teamId) {
                    const teamResponse = await api.get(`/team/status/${teamId}`);
                    const teamState = teamResponse.data;
                    localStorage.setItem(`questState_${questId}`, JSON.stringify(teamState));
                }

            } catch (error) {
                console.error('Error fetching quest:', error);
                setError(error.response?.data?.message || 'Failed to load quest data');

                if (error.response?.status === 404) {
                    navigate('/');
                }
            } finally {
                setLoading(false);
            }
        };

        if (questId) {
            fetchQuest();
        }
    }, [questId, navigate]);

    const updateQuest = useCallback(async (updates) => {
        try {
            setLoading(true);
            const questMode = localStorage.getItem(`questMode_${questId}`);
            const questModeData = questMode ? JSON.parse(questMode) : null;
            const isTeamMode = questModeData?.isTeam;
            const teamId = questModeData?.teamId;

            const updatedQuest = { ...quest, ...updates };
            setQuest(updatedQuest);
            localStorage.setItem('currentQuest', JSON.stringify(updatedQuest));

            if (isTeamMode && teamId) {
                await api.post(`/team/status/${teamId}`, updates);
            } else {
                await api.post(`/quest-state/${questId}/update`, updates);
            }
        } catch (error) {
            console.error('Error updating quest:', error);
            setError(error.response?.data?.message || 'Failed to update quest');
        } finally {
            setLoading(false);
        }
    }, [quest, questId]);

    const clearQuest = useCallback(() => {
        localStorage.removeItem('currentQuest');
        localStorage.removeItem(`questState_${questId}`);
        localStorage.removeItem(`questMode_${questId}`);
        setQuest(null);
    }, [questId]);

    const resetQuestState = useCallback(async () => {
        try {
            setLoading(true);
            const questMode = localStorage.getItem(`questMode_${questId}`);
            const questModeData = questMode ? JSON.parse(questMode) : null;
            const isTeamMode = questModeData?.isTeam;
            const teamId = questModeData?.teamId;

            // Очищаем состояние
            localStorage.removeItem(`questState_${questId}`);

            if (isTeamMode && teamId) {
                await api.post(`/team/${teamId}/reset`);
            } else {
                await api.post(`/quest-state/${questId}/reset`);
            }

            // Перезагружаем квест
            const response = await api.get(`/quests/${questId}`);
            setQuest(response.data);
            localStorage.setItem('currentQuest', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error resetting quest state:', error);
            setError(error.response?.data?.message || 'Failed to reset quest state');
        } finally {
            setLoading(false);
        }
    }, [questId]);

    return {
        quest,
        loading,
        error,
        updateQuest,
        clearQuest,
        resetQuestState
    };
};

export default useQuest;
