import { create } from 'zustand';
import { Howl } from 'howler';

const useAudioStore = create((set, get) => ({
  sound: null,
  isPlaying: false,
  progress: 0,
  duration: 0,
  isLoading: false,
  error: null,

  setError: (error) => {
    console.error('Audio error:', error);
    set({ error: error.toString() });
  },

  initSound: (url) => {
    console.log('=== Audio Debug Start ===');
    console.log('1. InitSound called with URL:', url);

    try {
      set({ isLoading: true, error: null });

      const currentSound = get().sound;
      if (currentSound) {
        console.log('2. Cleaning up previous sound');
        currentSound.unload();
      }

      if (!url) {
        console.log('3. No URL provided, skipping audio initialization');
        set({
          sound: null,
          isPlaying: false,
          progress: 0,
          duration: 0,
          isLoading: false
        });
        return;
      }

      // Validate audio format
      if (!url.match(/\.(mp3|wav|ogg|m4a)$/i)) {
        throw new Error('Unsupported audio format');
      }

      console.log('4. Creating new Howl instance');
      const sound = new Howl({
        src: [url],
        html5: true,
        preload: true,
        format: ['mp3', 'wav', 'ogg', 'm4a'],
        onload: () => {
          console.log('5. Audio loaded successfully');
          console.log('Duration:', sound.duration());
          set({
            duration: sound.duration(),
            isLoading: false
          });
          sound.play();
        },
        onplay: () => {
          console.log('6. Audio started playing');
          set({ isPlaying: true });
        },
        onend: () => {
          console.log('7. Audio playback ended');
          set({
            isPlaying: false,
            progress: 100
          });
        },
        onpause: () => {
          console.log('8. Audio paused');
          set({ isPlaying: false });
        },
        onstop: () => {
          console.log('9. Audio stopped');
          set({
            isPlaying: false,
            progress: 0
          });
        },
        onseek: () => {
          console.log('10. Audio seeked to:', sound.seek());
        },
        onloaderror: (id, error) => {
          console.error('ERROR: Failed to load audio:', {
            id,
            error,
            url
          });
          set({
            error: `Failed to load audio: ${error}`,
            isLoading: false
          });
        },
        onplayerror: (id, error) => {
          console.error('ERROR: Failed to play audio:', {
            id,
            error,
            url
          });
          set({
            error: `Failed to play audio: ${error}`,
            isLoading: false
          });
        }
      });

      sound.on('unlock', () => {
        console.log('Audio unlocked - mobile device ready for playback');
      });

      set({ sound });
      console.log('11. Howl instance created and stored');

    } catch (error) {
      console.error('CRITICAL ERROR in initSound:', error);
      set({
        error: `Error initializing audio: ${error.message}`,
        isLoading: false
      });
    }

    console.log('=== Audio Debug End ===');
  },

  togglePlayPause: () => {
    console.log('Toggle Play/Pause called');
    const { sound, isPlaying } = get();

    console.log('Current state:', {
      hasSound: !!sound,
      isPlaying,
      progress: get().progress
    });

    if (sound) {
      if (isPlaying) {
        console.log('Pausing audio');
        sound.pause();
      } else {
        console.log('Playing audio');
        sound.play();
      }
    } else {
      console.log('No sound instance available');
    }
  },

  skip: () => {
    console.log('Skip called');
    const { sound } = get();
    if (sound) {
      sound.stop();
      set({ progress: 100 });
      console.log('Audio skipped and marked as complete');
    } else {
      console.log('No sound instance to skip');
    }
  },

  updateProgress: () => {
    const { sound } = get();
    if (sound) {
      try {
        if (sound.playing()) {
          const seek = sound.seek() || 0;
          const duration = sound.duration() || 1;
          const progress = (seek / duration) * 100;

          // Log progress every 10%
          if (Math.floor(progress) % 10 === 0) {
            console.log(`Audio progress: ${Math.round(progress)}%`);
          }

          set({ progress });
        }
      } catch (error) {
        console.error('Error updating progress:', error);
        set({ error: `Progress update error: ${error.message}` });
      }
    }
  },

  setVolume: (volume) => {
    const { sound } = get();
    if (sound) {
      sound.volume(Math.max(0, Math.min(1, volume)));
      console.log(`Volume set to: ${volume}`);
    }
  },

  mute: () => {
    const { sound } = get();
    if (sound) {
      sound.mute(!sound.muted());
      console.log(`Audio ${sound.muted() ? 'muted' : 'unmuted'}`);
    }
  },

  seek: (position) => {
    const { sound } = get();
    if (sound) {
      sound.seek(position);
      console.log(`Seeked to: ${position}s`);
    }
  },

  cleanup: () => {
    console.log('Cleanup called');
    const { sound } = get();
    if (sound) {
      sound.unload();
      console.log('Sound unloaded');
    }
    set({
      sound: null,
      isPlaying: false,
      progress: 0,
      duration: 0,
      isLoading: false,
      error: null
    });
    console.log('State reset');
  }
}));

export default useAudioStore;

