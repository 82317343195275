import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import { 
  Card,
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from './ui/card';
import { Button } from './ui/button';
import { 
  MapPin, 
  Clock, 
  AlertCircle, 
  Trophy,
  UserCircle2,
  LogIn,
  UserPlus,
  Loader2
} from 'lucide-react';

const QuestCard = ({ quest }) => (
  <Card className="w-full transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <CardHeader className="space-y-1">
      <CardTitle className="text-2xl font-bold text-primary">
        {quest.name}
      </CardTitle>
      <CardDescription>
        {quest.description}
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div className="grid gap-4">
        <div className="flex items-center gap-4 text-sm">
          <div className="flex items-center gap-1">
            <Clock className="h-4 w-4 text-muted-foreground" />
            <span>{quest.duration} min</span>
          </div>
          <div className="flex items-center gap-1">
            <Trophy className="h-4 w-4 text-yellow-500" />
            <span>{quest.difficulty}</span>
          </div>
          <div className="flex items-center gap-1">
            <MapPin className="h-4 w-4 text-red-500" />
            <span>{quest.location || 'Jaffa'}</span>
          </div>
        </div>
        
        <Button asChild className="w-full">
          <Link to={`/quest/${quest._id}/start`}>
            Start Quest
          </Link>
        </Button>
      </div>
    </CardContent>
  </Card>
);

const StartScreen = () => {
  const [quests, setQuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchQuests = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/quests/');
        console.log('Loaded quests:', response.data);
        setQuests(response.data);
      } catch (error) {
        console.error('Error loading quests:', error);
        setError('Failed to load quests. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuests();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="space-y-4 text-center">
          <Loader2 className="w-10 h-10 animate-spin text-primary mx-auto" />
          <p className="text-muted-foreground">Loading available quests...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-red-500">
              <AlertCircle className="h-5 w-5" />
              Error
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-muted-foreground">{error}</p>
            <Button 
              className="w-full mt-4"
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-4 md:p-8">
      <div className="max-w-6xl mx-auto space-y-8">
        <div className="text-center space-y-2">
          <h1 className="text-4xl font-bold text-primary">
            Welcome to Heritage Trails
          </h1>
          <p className="text-xl text-muted-foreground">
            Discover the hidden gems of history through interactive quests
          </p>
        </div>

        {!token && (
          <div className="flex gap-4 justify-center">
            <Button asChild variant="outline">
              <Link to="/login" className="flex items-center gap-2">
                <LogIn className="h-4 w-4" />
                Login
              </Link>
            </Button>
            <Button asChild>
              <Link to="/register" className="flex items-center gap-2">
                <UserPlus className="h-4 w-4" />
                Register
              </Link>
            </Button>
          </div>
        )}

        {token && (
          <div className="flex justify-center">
            <Button asChild variant="outline">
              <Link to="/account" className="flex items-center gap-2">
                <UserCircle2 className="h-4 w-4" />
                My Account
              </Link>
            </Button>
          </div>
        )}

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {quests.length > 0 ? (
            quests.map((quest) => (
              <QuestCard key={quest._id} quest={quest} />
            ))
          ) : (
            <div className="col-span-full text-center text-muted-foreground">
              No quests available at the moment.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
