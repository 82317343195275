import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import { Loader2, CheckCircle, AlertCircle } from 'lucide-react';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [status, setStatus] = useState('processing');
    const [error, setError] = useState(null);

    useEffect(() => {
        const executePayment = async () => {
            console.log('Starting payment execution');
            const urlParams = new URLSearchParams(window.location.search);
            const paymentId = urlParams.get('paymentId');
            const PayerID = urlParams.get('PayerID');
            const questId = urlParams.get('questId');

            if (!paymentId || !PayerID || !questId) {
                console.error('Missing required parameters', { paymentId, PayerID, questId });
                setError('Invalid payment parameters');
                setTimeout(() => navigate('/payment-cancel'), 2000);
                return;
            }

            try {
                setStatus('processing_payment');
                console.log('Processing payment...', { paymentId, PayerID, questId });

                const response = await api.post('/account/complete-payment', {
                    payment_id: paymentId,
                    payer_id: PayerID,
                    quest_id: questId
                });

                console.log('Payment completed successfully, response:', response.data);

                if (response.data && response.data.status === 'success') {
                    setStatus('redirecting');
                    console.log('Preparing for redirect with data:', response.data);

                    const { is_team, participants } = response.data;

                    // Сохраняем режим квеста
                    const questModeData = {
                        isTeam: is_team,
                        participants: participants,
                        role: is_team ? null : 'Regular'
                    };
                    localStorage.setItem(`questMode_${questId}`, JSON.stringify(questModeData));

                    // Проверяем режим квеста
                    if (is_team === true) {
                        console.log('Redirecting to team creation...');
                        navigate(`/quest/${questId}/create-team`, {
                            state: { 
                                participants,
                                isTeam: true
                            }
                        });
                    } else {
                        console.log('Redirecting to solo quest...');
                        navigate(`/quest/${questId}/step/1/substep/0`, { replace: true });
                    }
                } else {
                    throw new Error('Invalid server response');
                }
            } catch (error) {
                console.error('Payment execution error:', error);
                setError(error.response?.data?.detail || 'Payment processing failed');
                setTimeout(() => navigate('/payment-cancel'), 2000);
            }
        };

        executePayment();
    }, [navigate]);

    const renderStatus = () => {
        switch (status) {
            case 'processing_payment':
                return 'Processing your payment...';
            case 'redirecting':
                return 'Payment successful! Redirecting...';
            default:
                return 'Processing...';
        }
    };

    const renderIcon = () => {
        if (error) {
            return <AlertCircle className="h-8 w-8 text-red-500" />;
        }
        if (status === 'redirecting') {
            return <CheckCircle className="h-8 w-8 text-green-500" />;
        }
        return <Loader2 className="h-8 w-8 animate-spin text-primary" />;
    };

    return (
        <div className="min-h-screen bg-background flex items-center justify-center p-4">
            <Card className="w-full max-w-md">
                <CardHeader className="space-y-2">
                    <div className="flex items-center gap-4">
                        {renderIcon()}
                        <CardTitle className="text-2xl">
                            Payment Processing
                        </CardTitle>
                    </div>
                </CardHeader>

                <CardContent>
                    {error ? (
                        <Alert variant="destructive">
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    ) : (
                        <div className="space-y-4">
                            <div className="h-2 w-full bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
                                <div
                                    className={`h-full transition-all duration-500 ${
                                        status === 'redirecting' ? 'bg-green-500' : 'bg-primary animate-pulse'
                                    }`}
                                    style={{
                                        width: status === 'redirecting' ? '100%' : '90%'
                                    }}
                                />
                            </div>

                            <div className="flex justify-between items-center text-sm text-muted-foreground">
                                <span>Payment Processing</span>
                                <span>Completion</span>
                            </div>

                            <div className="text-center text-sm text-muted-foreground mt-4">
                                {renderStatus()}
                            </div>

                            <div className="text-center text-sm text-muted-foreground mt-2">
                                Please do not close this window
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default PaymentSuccess;
