import { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { addEventListener, removeEventListener, sendMessage } from '../socket';
import log from 'loglevel';

const QuestStateContext = createContext();

export const useQuestState = () => {
  const context = useContext(QuestStateContext);
  if (!context) {
    throw new Error('useQuestState must be used within a QuestStateProvider');
  }
  return context;
};

export const QuestStateProvider = ({ children, initialState }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(() => ({
    questId: '',
    currentStep: 1,
    currentSubStep: 0,
    points: 100,
    role: '',
    isTeam: false,
    teamId: null,
    incorrectAttempts: 0,
    usedHints: [],
    completedSteps: [],
    error: null,
    lastUpdated: new Date(),
    ...initialState,
  }));

  const [isSyncing, setIsSyncing] = useState(false);
  const stateRef = useRef(state);
  const lastUpdateRef = useRef(null);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const syncToStorage = useCallback((newState) => {
    try {
      const storageKey = newState.isTeam ? 
        `teamState_${newState.teamId}` : 
        `questState_${newState.questId}`;
      localStorage.setItem(storageKey, JSON.stringify(newState));
    } catch (error) {
      console.error('Error syncing to storage:', error);
    }
  }, []);

  const updateState = useCallback(async (updates, skipSync = false) => {
    if (isSyncing) return;

    try {
      setIsSyncing(true);
      const timestamp = new Date().toISOString();

      // Подготовка данных для API в snake_case формате
      const stateData = {
        current_step: updates.currentStep || updates.current_step,
        current_substep: updates.currentSubStep || updates.current_substep,
        points: updates.points || stateRef.current.points,
        timestamp: timestamp
      };

      if (stateRef.current.isTeam && stateRef.current.teamId && !skipSync) {
        // Отправляем WebSocket сообщение
        sendMessage({
          type: 'state_update',
          data: stateData
        });

        // Ждем подтверждения через WebSocket
        const confirmed = await new Promise((resolve) => {
          const handleConfirmation = (data) => {
            if (data.timestamp === timestamp) {
              removeEventListener('state_update_confirmed', handleConfirmation);
              resolve(true);
            }
          };
          addEventListener('state_update_confirmed', handleConfirmation);
          // Таймаут 5 секунд
          setTimeout(() => resolve(false), 5000);
        });

        if (!confirmed) {
          throw new Error('State update confirmation timeout');
        }

        // После подтверждения WebSocket обновляем в БД
        await axios.post(
          `/api/team/status/${stateRef.current.teamId}`,
          stateData,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
      }

      // Обновляем локальное состояние
      const newState = {
        ...stateRef.current,
        ...updates,
        lastUpdated: timestamp
      };

      setState(newState);
      syncToStorage(newState);
      lastUpdateRef.current = { data: updates, timestamp };

      // В одиночном режиме или после подтверждения команды обновляем URL
      const { currentStep, currentSubStep } = updates;
      if (currentStep && currentSubStep) {
        navigate(
          `/quest/${stateRef.current.questId}/step/${currentStep}/substep/${currentSubStep}`,
          { replace: true }
        );
      }

    } catch (error) {
      console.error('Error updating state:', error);
      throw error;
    } finally {
      setIsSyncing(false);
    }
  }, [isSyncing, syncToStorage, navigate]);

  const applyHint = useCallback(async (hintId) => {
    const currentState = stateRef.current;
    if (currentState.usedHints.includes(hintId)) {
      return;
    }

    const newPoints = Math.max(0, currentState.points - 10);
    const newHints = [...currentState.usedHints, hintId];

    await updateState({
      points: newPoints,
      usedHints: newHints,
    });

    return { points: newPoints, usedHints: newHints };
  }, [updateState]);

  const addIncorrectAttempt = useCallback(async () => {
    const currentState = stateRef.current;
    const newPoints = Math.max(0, currentState.points - 5);
    const newAttempts = (currentState.incorrectAttempts || 0) + 1;

    await updateState({
      points: newPoints,
      incorrectAttempts: newAttempts,
    });

    return { points: newPoints, attempts: newAttempts };
  }, [updateState]);

  // Слушаем WebSocket обновления
  useEffect(() => {
    if (!state.isTeam) return;

    const handleStateUpdate = (data) => {
      if (!data?.timestamp || isSyncing) return;

      const currentState = stateRef.current;
      const newTimestamp = new Date(data.timestamp);
      const lastUpdateTimestamp = currentState.lastUpdated 
        ? new Date(currentState.lastUpdated)
        : new Date(0);

      if (newTimestamp > lastUpdateTimestamp) {
        if (currentState.isTeam && data.teamId !== currentState.teamId) {
          return;
        }

        updateState(data, true); // skipSync = true для избежания цикличных обновлений
      }
    };

    addEventListener('state_update', handleStateUpdate);
    return () => removeEventListener('state_update', handleStateUpdate);
  }, [state.isTeam, updateState, isSyncing]);

  // Загружаем начальное состояние
  useEffect(() => {
    const loadInitialState = async () => {
      try {
        if (!initialState.questId) return;

        const storageKey = initialState.isTeam ? 
          `teamState_${initialState.teamId}` : 
          `questState_${initialState.questId}`;
        
        const savedState = localStorage.getItem(storageKey);
        
        if (savedState) {
          const parsed = JSON.parse(savedState);
          setState(prev => ({
            ...prev,
            ...parsed,
          }));
        }
      } catch (error) {
        console.error('Error loading initial state:', error);
      }
    };

    loadInitialState();
  }, [initialState.questId, initialState.teamId, initialState.isTeam]);

  const value = {
    ...state,
    updateState,
    applyHint,
    addIncorrectAttempt,
    isSyncing,
  };

  return (
    <QuestStateContext.Provider value={value}>
      {children}
    </QuestStateContext.Provider>
  );
};

QuestStateProvider.defaultProps = {
  initialState: {
    questId: '',
    currentStep: 1,
    currentSubStep: 0,
    points: 100,
    role: '',
    isTeam: false,
    teamId: null,
    incorrectAttempts: 0,
    usedHints: [],
    completedSteps: [],
  },
};

export default QuestStateProvider;
