import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import { Users, ArrowRight, Loader2, AlertCircle, Shield } from 'lucide-react';
import api from '../api';

const TeamJoin = () => {
    const [code, setCode] = useState('');
    const [isJoining, setIsJoining] = useState(false);
    const [error, setError] = useState(null);
    const [teamInfo, setTeamInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id: questId } = useParams();

    useEffect(() => {
        const checkExistingTeam = async () => {
            try {
                const questMode = localStorage.getItem(`questMode_${questId}`);
                if (questMode) {
                    const { isTeam, teamId } = JSON.parse(questMode);
                    if (isTeam && teamId) {
                        const response = await api.get(`/team/status/${teamId}`);
                        if (response.data && ['waiting', 'in_progress'].includes(response.data.status)) {
                            navigate(`/team/${teamId}/waiting`);
                            return;
                        }
                    }
                }
            } catch (error) {
                console.error('Error checking team status:', error);
            } finally {
                setIsLoading(false);
            }
        };

        checkExistingTeam();
    }, [questId, navigate]);

    const validateTeamCode = async (inputCode) => {
        if (inputCode.length === 6) {
            try {
                console.log('Validating team code:', inputCode);
                setError(null);
                
                const response = await api.post('/team/validate-code', {
                    code: inputCode.toUpperCase(),
                    quest_id: questId
                });

                console.log('Validation response:', response.data);
                
                if (response.data) {
                    if (response.data.isMember) {
                        const questModeData = {
                            isTeam: true,
                            role: null,
                            teamId: response.data.teamId,
                            questId: questId
                        };
                        localStorage.setItem(`questMode_${questId}`, JSON.stringify(questModeData));
                        navigate(`/team/${response.data.teamId}/waiting`);
                        return;
                    }
                    setTeamInfo(response.data);
                } else {
                    setTeamInfo(null);
                    setError('Invalid team code');
                }
            } catch (error) {
                console.error('Error validating team code:', error);
                setTeamInfo(null);
                setError(error.response?.data?.detail || 'Invalid team code');
            }
        } else {
            setTeamInfo(null);
            setError(null);
        }
    };

    const handleCodeChange = (e) => {
        const newCode = e.target.value.toUpperCase().slice(0, 6);
        setCode(newCode);
        if (newCode.length === 6) {
            validateTeamCode(newCode);
        }
    };

    const handleJoin = async () => {
        if (!code.trim()) {
            setError('Please enter a team code');
            return;
        }

        if (!questId) {
            setError('Quest ID is missing');
            return;
        }

        try {
            setIsJoining(true);
            setError(null);

            console.log('Joining team with code:', code.trim(), 'and quest_id:', questId);

            const response = await api.post('/team/join-by-code', {
                code: code.trim().toUpperCase(),
                quest_id: questId
            });

            console.log('Join response:', response.data);

            if (response.data && response.data.team_id) {
                const questModeData = {
                    isTeam: true,
                    role: null,
                    teamId: response.data.team_id,
                    questId: questId
                };
                localStorage.setItem(`questMode_${questId}`, JSON.stringify(questModeData));
                navigate(`/team/${response.data.team_id}/waiting`);
            } else {
                throw new Error('Invalid server response');
            }
        } catch (error) {
            console.error('Error joining team:', error);
            setError(error.response?.data?.detail || 'Failed to join team. Please check the code and try again.');
        } finally {
            setIsJoining(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && code.trim() && !isJoining && teamInfo) {
            handleJoin();
        }
    };

    const handleBack = () => {
        localStorage.removeItem(`questMode_${questId}`);
        navigate(`/quest/${questId}/start`);
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <Loader2 className="w-8 h-8 animate-spin" />
            </div>
        );
    }

    return (
        <Card className="w-full max-w-md mx-auto">
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Users className="h-5 w-5" />
                    Join Team
                </CardTitle>
                <CardDescription>
                    Enter the team code to join an existing quest team
                </CardDescription>
            </CardHeader>

            <CardContent className="space-y-4">
                {error && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                <div className="space-y-4">
                    <div className="space-y-2">
                        <Input
                            placeholder="Enter 6-digit team code"
                            value={code}
                            onChange={handleCodeChange}
                            onKeyPress={handleKeyPress}
                            disabled={isJoining}
                            maxLength={6}
                            className="text-center text-lg tracking-widest font-mono uppercase"
                        />

                        {teamInfo && (
                            <Alert className="bg-primary/10 border-primary/20">
                                <AlertDescription className="flex flex-col gap-1">
                                    <span className="font-medium">{teamInfo.name}</span>
                                    <span className="text-sm text-muted-foreground">
                                        {teamInfo.memberCount} / {teamInfo.maxParticipants} members
                                    </span>
                                </AlertDescription>
                            </Alert>
                        )}
                    </div>

                    <div className="flex flex-col sm:flex-row gap-2">
                        <Button
                            className="flex-1"
                            onClick={handleJoin}
                            disabled={!code.trim() || isJoining || !teamInfo}
                        >
                            {isJoining ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Joining...
                                </>
                            ) : (
                                <>
                                    Join Team
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </>
                            )}
                        </Button>

                        <Button
                            variant="outline"
                            onClick={handleBack}
                            disabled={isJoining}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>

                <div className="text-sm text-muted-foreground mt-6 space-y-2">
                    <h4 className="font-medium text-primary">Available Roles</h4>
                    <div className="space-y-2">
                        <div className="flex items-center gap-2">
                            <Shield className="h-4 w-4 text-green-500" />
                            <span className="font-medium">Gamer:</span>
                            <span>Submit answers and solve puzzles</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Shield className="h-4 w-4 text-blue-500" />
                            <span className="font-medium">Listener:</span>
                            <span>Focus on audio challenges</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Shield className="h-4 w-4 text-purple-500" />
                            <span className="font-medium">Regular:</span>
                            <span>All-around support</span>
                        </div>
                    </div>
                </div>

                <Alert>
                    <AlertDescription>
                        You'll be able to select your role after joining the team.
                        The quest will begin when all team members are ready.
                    </AlertDescription>
                </Alert>
            </CardContent>
        </Card>
    );
};

export default TeamJoin;
