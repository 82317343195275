import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Play, Pause, SkipForward, Volume2, VolumeX } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import useAudioStore from '../hooks/useAudioStore';

const SpeechStage = ({ 
    character, 
    text, 
    audioFile,
    questId,
    onNext,
    isTeam = false,
    teamId = null
}) => {
    const [visible, setVisible] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const textRef = useRef(null);
    
    const {
        sound,
        isPlaying,
        progress,
        duration,
        isLoading,
        error,
        initSound,
        togglePlayPause,
        skip,
        setVolume,
        cleanup
    } = useAudioStore();

    useEffect(() => {
        setVisible(true);
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            initSound(audioUrl);
        }

        return () => {
            setVisible(false);
            cleanup();
        };
    }, [audioFile, questId, initSound, cleanup]);

    const handleNext = () => {
        setVisible(false);
        setTimeout(() => {
            onNext();
        }, 500);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        setVolume(isMuted ? 1 : 0);
    };

    const handleSkip = () => {
        skip();
    };

    const renderTextWithAnimation = () => {
        const words = text.split(' ');
        const totalDuration = 1000;
        const delayPerWord = totalDuration / words.length;

        return (
            <div className="space-x-1">
                {words.map((word, index) => (
                    <span
                        key={index}
                        className="inline-block opacity-0 animate-fade-in"
                        style={{
                            animationDelay: `${index * delayPerWord}ms`,
                            animationFillMode: 'forwards'
                        }}
                    >
                        {word}{' '}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <Card className={`w-full max-w-3xl mx-auto transition-all duration-500 ${
            visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
        }`}>
            <CardHeader>
                <CardTitle className="flex items-center justify-between">
                    <span className="text-xl md:text-2xl text-primary font-serif">
                        {character}
                    </span>
                    {audioFile && (
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={toggleMute}
                                className="rounded-full p-2"
                            >
                                {isMuted ? (
                                    <VolumeX className="h-5 w-5 text-gray-500" />
                                ) : (
                                    <Volume2 className="h-5 w-5 text-gray-500" />
                                )}
                            </Button>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setShowTranscript(!showTranscript)}
                                className="text-sm"
                            >
                                {showTranscript ? 'Hide' : 'Show'} Transcript
                            </Button>
                        </div>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-4">
                {audioFile ? (
                    <div className="space-y-4">
                        <div className="flex items-center justify-center space-x-4">
                            <Button
                                onClick={togglePlayPause}
                                disabled={isLoading}
                                className="rounded-full w-12 h-12 flex items-center justify-center"
                            >
                                {isPlaying ? (
                                    <Pause className="h-6 w-6" />
                                ) : (
                                    <Play className="h-6 w-6" />
                                )}
                            </Button>
                            {!isTeam && (
                                <Button
                                    onClick={handleSkip}
                                    variant="outline"
                                    className="rounded-full"
                                >
                                    <SkipForward className="h-5 w-5" />
                                </Button>
                            )}
                        </div>

                        <div className="w-full bg-gray-200 rounded-full h-2">
                            <div
                                className="bg-primary rounded-full h-full transition-all duration-300"
                                style={{ width: `${progress}%` }}
                            />
                        </div>

                        {error && (
                            <div className="text-red-500 text-center text-sm">
                                {error}
                            </div>
                        )}
                    </div>
                ) : null}

                <div
                    ref={textRef}
                    className={`prose max-w-none transition-opacity duration-500 ${
                        showTranscript || !audioFile ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    {renderTextWithAnimation()}
                </div>

                <div className="flex justify-center pt-4">
                    <Button
                        onClick={handleNext}
                        className="transition-all duration-300 hover:scale-105"
                    >
                        Continue
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

SpeechStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string
};

export default SpeechStage;
