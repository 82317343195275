import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import {
    AlertCircle,
    Clock,
    CreditCard,
    Users,
    ChevronLeft,
    Loader2,
    Trophy,
    User,
    LogIn,
    PlusCircle,
} from 'lucide-react';

const QuestPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [quest, setQuest] = useState(null);
    const [questStatus, setQuestStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTeamMode, setIsTeamMode] = useState(false);
    const [participants, setParticipants] = useState(2);

    const checkQuestStatus = async () => {
        try {
            const statusResponse = await api.get(`/account/quest-status/${id}`);
            setQuestStatus(statusResponse.data);
            
            if (statusResponse.data.status === 'purchased') {
                // Проверяем режим квеста
                if (statusResponse.data.is_team) {
                    // Проверяем наличие команды
                    if (statusResponse.data.has_team) {
                        navigate(`/team/${statusResponse.data.team_id}/waiting`);
                    } else {
                        // Если команды нет - перенаправляем на создание
                        navigate(`/quest/${id}/create-team`, {
                            state: { 
                                participants: statusResponse.data.participants,
                                questId: id  // Добавляем questId
                            }
                        });
                    }
                    return true;
                } else {
                    navigate(`/quest/${id}/step/1/substep/0`);
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error('Error checking quest status:', error);
            if (error.response?.status === 401) {
                // Если токен истек, попытка обновить
                try {
                    const refresh_token = localStorage.getItem('refreshToken');
                    if (refresh_token) {
                        await api.post('/account/refresh-token', { refresh_token });
                        return await checkQuestStatus(); // Повторная попытка после обновления токена
                    }
                } catch (refreshError) {
                    console.error('Error refreshing token:', refreshError);
                    navigate('/login');
                }
            }
            return false;
        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                // Загружаем информацию о квесте
                const questResponse = await api.get(`/quests/${id}`);
                setQuest(questResponse.data);

                // Проверяем статус и перенаправляем если нужно
                const shouldRedirect = await checkQuestStatus();
                if (shouldRedirect) {
                    return;
                }

                // Сохраняем режим из localStorage если есть
                const questMode = localStorage.getItem(`questMode_${id}`);
                if (questMode) {
                    const { isTeam, participants: savedParticipants } = JSON.parse(questMode);
                    setIsTeamMode(isTeam);
                    if (savedParticipants) {
                        setParticipants(savedParticipants);
                    }
                }

            } catch (error) {
                console.error('Error loading data:', error);
                setError('Failed to load quest information');
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, [id, navigate]);

    const handlePayment = async () => {
        if (!quest) return;

        try {
            setIsProcessing(true);
            setError(null);

            const paymentData = {
                quest_id: id,
                amount: isTeamMode ? quest.price * participants : quest.price,
                payment_method: 'PayPal',
                participants: isTeamMode ? participants : 1,
                is_team: isTeamMode
            };

            const response = await api.post('/account/pay', paymentData);

            if (response.data.redirect_url) {
                // Сохраняем выбранный режим перед редиректом
                localStorage.setItem(`questMode_${id}`, JSON.stringify({
                    isTeam: isTeamMode,
                    participants: participants,
                    questId: id  // Добавляем questId
                }));

                window.location.href = response.data.redirect_url;
            } else {
                setError('Invalid payment response');
            }
        } catch (error) {
            console.error('Payment error:', error);
            setError(error.response?.data?.detail || 'Error processing payment');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleModeChange = (teamMode) => {
        setIsTeamMode(teamMode);
        if (teamMode) {
            setParticipants(2);
        } else {
            setParticipants(1);
        }
        // Сохраняем выбранный режим
        localStorage.setItem(`questMode_${id}`, JSON.stringify({
            isTeam: teamMode,
            participants: teamMode ? 2 : 1,
            questId: id
        }));
    };

    const handleCreateTeam = () => {
        navigate(`/quest/${id}/create-team`, { 
            state: { 
                participants,
                questId: id 
            }
        });
    };

    const handleJoinTeam = () => {
        navigate(`/quest/${id}/join-team`, {
            state: { 
                questId: id 
            }
        });
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="space-y-4 text-center">
                    <Loader2 className="w-10 h-10 animate-spin text-primary mx-auto" />
                    <p className="text-muted-foreground">Loading quest details...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen p-4 flex items-center justify-center">
                <Card className="w-full max-w-md">
                    <CardHeader>
                        <CardTitle className="flex items-center text-red-500 gap-2">
                            <AlertCircle className="h-5 w-5" />
                            Error
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <AlertDescription>{error}</AlertDescription>
                        <Button className="w-full" onClick={() => window.location.reload()}>
                            Try Again
                        </Button>
                    </CardContent>
                </Card>
            </div>
        );
    }

    if (!quest) return null;

    const totalPrice = isTeamMode ? quest.price * participants : quest.price;

    return (
        <div className="min-h-screen bg-background p-4 md:p-8">
            <div className="max-w-4xl mx-auto space-y-6">
                <Card>
                    <CardHeader>
                        <CardTitle className="text-3xl font-bold text-primary">
                            {quest.name}
                        </CardTitle>
                        <CardDescription className="text-lg">
                            {quest.description}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="flex items-center gap-2">
                                <Clock className="h-5 w-5 text-muted-foreground" />
                                <span>{quest.duration} minutes</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Trophy className="h-5 w-5 text-yellow-500" />
                                <span>{quest.difficulty}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Users className="h-5 w-5 text-blue-500" />
                                <span>1-{quest.maxParticipants || 10} players</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {(!questStatus || questStatus.status !== 'purchased') && (
                    <Card>
                        <CardHeader>
                            <CardTitle>Purchase Quest</CardTitle>
                            <CardDescription>
                                Select game mode and make payment
                            </CardDescription>
                        </CardHeader>
                        <CardContent className="space-y-6">
                            <div className="flex gap-4 justify-center">
                                <Button
                                    variant={isTeamMode ? "outline" : "default"}
                                    onClick={() => handleModeChange(false)}
                                    className="flex-1"
                                >
                                    <User className="mr-2 h-4 w-4" />
                                    Solo Mode
                                </Button>
                                <Button
                                    variant={isTeamMode ? "default" : "outline"}
                                    onClick={() => handleModeChange(true)}
                                    className="flex-1"
                                >
                                    <Users className="mr-2 h-4 w-4" />
                                    Team Mode
                                </Button>
                            </div>

                            {isTeamMode && (
                                <>
                                    <div className="space-y-2">
                                        <label className="text-sm font-medium">
                                            Number of Players
                                        </label>
                                        <select
                                            value={participants}
                                            onChange={(e) => {
                                                const value = Math.min(
                                                    Math.max(Number(e.target.value), 2),
                                                    quest.maxParticipants || 10
                                                );
                                                setParticipants(value);
                                                localStorage.setItem(
                                                    `questMode_${id}`,
                                                    JSON.stringify({
                                                        isTeam: true,
                                                        participants: value,
                                                        questId: id
                                                    })
                                                );
                                            }}
                                            className="w-full p-2 border rounded-md bg-background"
                                            disabled={isProcessing}
                                        >
                                            {Array.from(
                                                { length: (quest.maxParticipants || 10) - 1 },
                                                (_, i) => i + 2
                                            ).map((num) => (
                                                <option key={num} value={num}>
                                                    {num} players (${(quest.price * num).toFixed(2)})
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="space-y-4">
                                        <div className="text-center">
                                            <p className="text-2xl font-bold text-primary">
                                                ${totalPrice.toFixed(2)}
                                            </p>
                                            <p className="text-sm text-muted-foreground">
                                                Team price for {participants} players
                                            </p>
                                        </div>

                                        <Button
                                            className="w-full"
                                            onClick={handlePayment}
                                            disabled={isProcessing}
                                        >
                                            {isProcessing ? (
                                                <>
                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                    Processing...
                                                </>
                                            ) : (
                                                <>
                                                    <CreditCard className="mr-2 h-4 w-4" />
                                                    Purchase and Create Team
                                                </>
                                            )}
                                        </Button>

                                        <div className="flex items-center justify-center space-x-2">
                                            <span className="text-muted-foreground">
                                                or
                                            </span>
                                        </div>

                                        <Button
                                            variant="outline"
                                            className="w-full"
                                            onClick={handleJoinTeam}
                                        >
                                            <LogIn className="mr-2 h-4 w-4" />
                                            Join Existing Team
                                        </Button>
                                    </div>
                                </>
                            )}

                            {!isTeamMode && (
                                <div className="space-y-4">
                                    <div className="text-center">
                                        <p className="text-2xl font-bold text-primary">
                                            ${totalPrice.toFixed(2)}
                                        </p>
                                        <p className="text-sm text-muted-foreground">
                                            Solo player price
                                        </p>
                                    </div>

                                    <Button
                                        className="w-full"
                                        onClick={handlePayment}
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Processing...
                                            </>
                                        ) : (
                                            <>
                                                <CreditCard className="mr-2 h-4 w-4" />
                                                Purchase Now
                                            </>
                                        )}
                                    </Button>
                                </div>
                            )}

                            {error && (
                                <Alert variant="destructive">
                                    <AlertCircle className="h-4 w-4" />
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}
                        </CardContent>
                    </Card>
                )}

                <Button
                    variant="outline"
                    onClick={() => navigate('/')}
                    className="w-full"
                >
                    <ChevronLeft className="mr-2 h-4 w-4" />
                    Back to Quests
                </Button>
            </div>
        </div>
    );
};

export default QuestPage;
