import * as React from "react"
import { cva } from "class-variance-authority"
import { cn } from "../../lib/utils"
import { X } from 'lucide-react'

const alertVariants = cva(
  "relative w-full rounded-lg border px-4 py-3 text-sm [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        success: "border-green-500/50 text-green-700 dark:text-green-400 bg-green-50 dark:bg-green-900/20 [&>svg]:text-green-500",
        destructive:
          "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
        warning: "border-yellow-500/50 text-yellow-700 dark:text-yellow-400 bg-yellow-50 dark:bg-yellow-900/20 [&>svg]:text-yellow-500",
        info: "border-blue-500/50 text-blue-700 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20 [&>svg]:text-blue-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Alert = React.forwardRef(({ className, variant, children, onClose, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  >
    {children}
    {onClose && (
      <button
        onClick={onClose}
        className="absolute right-2 top-2 rounded-lg p-1 opacity-70 transition-opacity hover:opacity-100"
      >
        <X className="h-4 w-4" />
      </button>
    )}
  </div>
))
Alert.displayName = "Alert"

const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
))
AlertTitle.displayName = "AlertTitle"

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
))
AlertDescription.displayName = "AlertDescription"

const Alert2 = React.forwardRef(({ className, children, variant = "default", title, onClose, ...props }, ref) => {
  const [isVisible, setIsVisible] = React.useState(true)
  
  const handleClose = () => {
    setIsVisible(false)
    if (onClose) onClose()
  }

  if (!isVisible) return null

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(
        "relative w-full rounded-lg border p-4 transition-all duration-300",
        variant === "success" && "border-green-500/50 text-green-700 dark:text-green-400 bg-green-50 dark:bg-green-900/20",
        variant === "error" && "border-red-500/50 text-red-700 dark:text-red-400 bg-red-50 dark:bg-red-900/20",
        variant === "warning" && "border-yellow-500/50 text-yellow-700 dark:text-yellow-400 bg-yellow-50 dark:bg-yellow-900/20",
        variant === "info" && "border-blue-500/50 text-blue-700 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20",
        className
      )}
      {...props}
    >
      {title && (
        <div className="mb-2 font-medium">
          {title}
        </div>
      )}
      <div className="text-sm">
        {children}
      </div>
      {onClose && (
        <button
          onClick={handleClose}
          className="absolute right-2 top-2 rounded-lg p-1 opacity-70 transition-opacity hover:opacity-100"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
})
Alert2.displayName = "Alert2"

export { Alert, Alert2, AlertTitle, AlertDescription }
